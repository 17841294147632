import { v4 as uuid4 } from 'uuid';
import { record } from '../../recorder';
import { initalizeConsoleLog } from '../lib/collectLog';
import { initalizeNetworkLog } from '../lib/collectNetwork'
import { getBrowserData } from '../lib/getMetadata';
import Api from './api';
import { DEFAULT_SESSION_REPLAY_BLOCK_CLASS, DEFAULT_SESSION_REPLAY_MASK_CLASS, CAPTURE_IMAGES_INLINE, DEFAULT_SESSION_THROTTLE, CAPTURE_INLINE_STYLESHEET, MASK_INPUT_CLASS } from '../utils';
import { listenerHandler } from '../../recorder/typings/types';
import { UserIdleTracker } from './userIdleTracker';
import { processEvent } from '../lib/processUEEvents';
import { hashFunction } from '../lib/hashFunction';

export let isRecording = false
export let sessionEvents: Array<object> = []
export let logEvents: Array<object> = []
export let lastUploadedTime: number;         

export const resetSessionEvents = (): void => {
    sessionEvents = []
}
export const resetLogEvents = (): void => {
    logEvents = []
}

export const setLastUploadedTime = () => {
    lastUploadedTime = Math.floor(Date.now() / 1000)
    localStorage.setItem('ue_last_upload', lastUploadedTime.toString())
}

export interface UserExperiorOptions {
    sessionReplay: SessionReplayOptions
}

interface SessionReplayOptions {
    maskAllInputs?: boolean;
    maskInputOptions?: MaskInputOptions;
    captureMouseMove?: boolean;
    captureNetworkLogs?: boolean;
    captureConsoleLogs?: boolean;
    enableUserId?: boolean;
}

type MaskInputOptions = Partial<{
    color: boolean;
    date: boolean;
    'datetime-local': boolean;
    email: boolean;
    month: boolean;
    number: boolean;
    range: boolean;
    search: boolean;
    tel: boolean;
    text: boolean;
    time: boolean;
    url: boolean;
    week: boolean;
    // unify textarea and select element with text input
    textarea: boolean;
    select: boolean;
    password: boolean;
}>;

export default class UserExperior {
    readonly projectKey: string;
    private restartSessionHandler: () => void;
    private sessionToken: string;
    private recorderInstance: listenerHandler | undefined;
    private api: Api | undefined;
    private logIntervalInstance: NodeJS.Timer | undefined;
    private userIdleTrackerInstance: UserIdleTracker | undefined;
    userId: string | null;
    userProperties: object | {};
    private tabId: string;
    private captureMouseMove: boolean;
    private captureConsoleLogs: boolean;
    private captureNetworkLogs: boolean;
    private enableUserId: boolean;
    options: UserExperiorOptions;
    constructor(
        projectKey: string,
        options: UserExperiorOptions,
    ) {
        this.projectKey = projectKey
        this.sessionToken = ''
        this.userId = null
        this.userProperties = {}
        this.tabId = uuid4()
        this.options = options
        this.restartSessionHandler = this.restartSession.bind(this)
        this.captureMouseMove = options.sessionReplay.captureMouseMove ? options.sessionReplay.captureMouseMove : false;
        this.captureConsoleLogs = options.sessionReplay.captureConsoleLogs == false ? false : true;
        this.captureNetworkLogs = options.sessionReplay.captureNetworkLogs == false ? false : true;
        this.enableUserId = options.sessionReplay.enableUserId || false;
    }
    startRecording(): void {
        // User will call this menthod to initiate the recording
        // If session exists get the session id else set new session id
        if (!isRecording) {
            this.sessionToken = uuid4()
            const existingSessionId = localStorage.getItem('ue_session') || sessionStorage.getItem('ue_session')
            const existingLastUpload = localStorage.getItem('ue_last_upload')
            const currentTime = Math.floor(Date.now() / 1000)
            let userId: string = 'Anonymous'
            const userIdString = `${window.navigator.userAgent}-${window.navigator.platform}-${window.innerHeight}-${window.innerWidth}-${Date.now()}-${this.sessionToken.split('-')[0]}`
            if (this.enableUserId) {
                const hashedUser = hashFunction(userIdString)
                userId = `Anon-${hashedUser}`
            }

            // adding new session id if:
            // - session id not exists or
            // - closed all tabs or closed the browser
            if (!existingSessionId || (existingLastUpload && currentTime - parseInt(existingLastUpload) >= DEFAULT_SESSION_THROTTLE)) {
                localStorage.setItem('ue_session', this.sessionToken)
                sessionStorage.setItem('ue_session', this.sessionToken)
            } else {
                this.sessionToken = existingSessionId;
            }

            // If tab id exists get the tab id else set the tab id
            const existingTabId = sessionStorage.getItem('ue_tab')
            if (!existingTabId) {
                sessionStorage.setItem('ue_tab', this.tabId)
            } else {
                this.tabId = existingTabId
            }

            //initilizing api by passing projectKey and sessionToken
            this.api = new Api(this.projectKey, this.sessionToken, this.tabId)

            //sending inital set of data
            this.api.createSession({
                sessionMetaData: getBrowserData(),
                identifier: localStorage.getItem('userIdentifier') || sessionStorage.getItem('userIdentifier') || userId
            })

            //initalize session replay, collecting console logs and network logs
            this.initalizeSessionRecording();
        }
    }
    setUserIdentifier(id: string, userProperties: object = {}): void {
        setTimeout(() => {
            if (id) {
                this.userId = id
                this.userProperties = userProperties
                this.api?.sendUser({ user: this.userId, userProperties: this.userProperties })
                localStorage.setItem('userIdentifier', id)
                sessionStorage.setItem('userIdentifier', id)
            }
        }, 1);
    }
    setUserProperties(userTraits: object): void {
        if (Object.keys(userTraits).length) {
            //if user identifier intialized then sending user traits
            this.api?.sendUser({ user: this.userId, userProperties: userTraits })
        }
    }
    initalizeSessionRecording() {
        isRecording = true
        //user activity tracker instance
        this.userIdleTrackerInstance = new UserIdleTracker(this.restartSessionHandler)
        setLastUploadedTime()
        if(this.captureConsoleLogs) {
            initalizeConsoleLog()
        }   
        if(this.captureNetworkLogs) {
            initalizeNetworkLog()
        }
        this.recorderInstance = record({
            emit(event) {
                processEvent(event);
                sessionEvents.push(event);
            },
            blockClass: DEFAULT_SESSION_REPLAY_BLOCK_CLASS,
            maskTextClass: DEFAULT_SESSION_REPLAY_MASK_CLASS,
            maskAllInputs: this.options.sessionReplay.maskAllInputs,
            maskInputOptions: this.options.sessionReplay.maskInputOptions,
            inlineImages: CAPTURE_IMAGES_INLINE,
            inlineStylesheet: CAPTURE_INLINE_STYLESHEET,
            maskInputClass: MASK_INPUT_CLASS,
            sampling: { input: 'last', mousemove: this.captureMouseMove }
        });
        this.logIntervalInstance = setInterval(() => this.api?.sendLogs(false, this.stopRecording.bind(this)), 2 * 1000)
    }
    logEvent(event: string, eventDetails: object = {}): void {
        //sending log event
        logEvents.push({
            event: event,
            event_details: eventDetails,
            epochTime: Math.floor(Date.now() / 1000),
            timestamp: Date.now()
        })
    }
    unsetUserIdentifier(): void {
        if (this.userId) {
            this.userId = null
            localStorage.removeItem('userIdentifier')
            sessionStorage.removeItem('userIdentifier')
            this.restartSession()
        }
    }
    stopRecording(): void {
        //stop complete recording
        if (isRecording) {
            //removing session id from localstorage
            localStorage.removeItem('ue_session')
            sessionStorage.removeItem('ue_session')

            //clearing rrweb record instance
            if (this.recorderInstance) {
                this.recorderInstance()
            }
            //setting isRecording false to not capture network and console logs
            isRecording = false

            //removing event tracking listeners
            // removeEventListeners()

            //clearing log sending interval
            if (this.logIntervalInstance) {
                clearInterval(this.logIntervalInstance)
            }

            //clearing log user activity tracker event listeners
            if (this.userIdleTrackerInstance) {
                this.userIdleTrackerInstance.cleanUpTracker()
            }
        }
    }

    restartSession(): void {
        //change the session id and reinitialize session recording
        if (isRecording) {
            //force sending the logs of previous session
            this.api?.sendLogs(true, this.stopRecording.bind(this));

            //removing session id from localstorage
            localStorage.removeItem('ue_session')
            sessionStorage.removeItem('ue_session')

            //clearing rrweb record instance
            if (this.recorderInstance) {
                this.recorderInstance()
            }
            //setting isRecording false to not capture network and console logs
            isRecording = false

            //removing event tracking listeners
            // removeEventListeners()

            //clearing log sending interval
            if (this.logIntervalInstance) {
                clearInterval(this.logIntervalInstance)
            }

            //clearing log user activity tracker event listeners
            if (this.userIdleTrackerInstance) {
                this.userIdleTrackerInstance.cleanUpTracker()
            }

            //restarting the session recording
            this.startRecording();
        }
    }
}
