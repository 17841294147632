import { DEFAULT_NETWORK_BODY_SIZE } from '../utils'
import { getSizeOfData } from './helper';
import { objectStringAccess } from './getMetadata';
import { isRecording } from '../app';

interface NetworkEventInterface {
    request: RequestInterface,
    response: ResponseInterface,
    epochTime?: number,
    type: string,
}
interface RequestInterface {
    url?: string,
    method?: string,
    startTime?: string,
    headers: {
        [key: string]: string | null
    },
    body?: unknown,
    transfer_encoding?: string
    sizeInKB?: number,
    limitExceeded?: string,
}

interface ResponseInterface {
    status: number,
    endTime: string,
    headers: string | object,
    body?: unknown,
    transfer_encoding?: string,
    sizeInKB?: number,
    limitExceeded?: string,
    extra?: object
}

let resourceTimeStart = 0

const captureXMLHttpRequestEvent = (recorder: (event: NetworkEventInterface) => void) => {
    const XHR: XMLHttpRequest = window.XMLHttpRequest.prototype;
    const send = XHR.send;
    const setRequestHeader = XHR.setRequestHeader;

    const requestObject: RequestInterface = {
        headers: {}
    }

    // Collect data:
    const open = XHR.open;
    XHR.open = function (method: string, url: string | URL, async?: boolean, username?: string | undefined, password?: string | undefined): void {
        requestObject.method = method
        requestObject.url = url.toString()
        requestObject.headers = {}
        requestObject.startTime = (new Date()).toISOString()
        return open.apply(this, [method, url, async ? async : true, username, password]);
    };

    XHR.setRequestHeader = function (header: string, value: string): void {
        requestObject.headers[header] = value
        return setRequestHeader.apply(this, [header, value]);
    };

    XHR.send = function (postData): void {
        this.addEventListener('loadend', function (_event, request = { ...requestObject }) {
            const endTime = (new Date()).toISOString();
            if (recorder) {
                const requestUrl: string | undefined = request.url?.toLowerCase();
                if (requestUrl) {
                    if (postData) {
                        if (typeof postData === 'string') {
                            try {
                                request.body = postData;
                            } catch (err) {
                                request.transfer_encoding = 'base64'
                                request.body = btoa(postData);
                            }
                        } else if (typeof postData === 'object' || typeof postData === 'number' || typeof postData === 'boolean') {
                            request.body = postData;
                        }
                    }
                    const responseHeaders = this.getAllResponseHeaders();

                    const responseObject: ResponseInterface = {
                        status: this.status,
                        endTime: endTime,
                        headers: responseHeaders,
                    };

                    if (this.responseType != 'document' && (this.responseType == 'blob' || this.responseText)) {
                        // responseText is string or null
                        try {
                            responseObject.body = this.responseText;
                        } catch (err) {
                            responseObject.transfer_encoding = 'base64';
                            responseObject.body = btoa(this.response);
                        }
                    }

                    const event: NetworkEventInterface = {
                        request: request,
                        response: responseObject,
                        type: 'xhr',
                    };
                    recorder(event);
                }
            }
        });
        return send.apply(this, [postData]);
    };

    return null;
}

const getFetchHeaders = (headers: Headers | object): { [key: string]: string | null } => {
    const headerDict: objectStringAccess = {}
    if (headers instanceof Headers) {
        headers.forEach((value, key) => {
            headerDict[key.toLowerCase()] = JSON.stringify(value)
        })
    } else {
        for (const [key, value] of Object.entries(Object(headers))) {
            headerDict[key.toLowerCase()] = JSON.stringify(value)
        }
    }
    return headerDict
}

const captureFetchRequestEvent = (recorder: (event: NetworkEventInterface) => void) => {
    const { fetch: origFetch } = window;
    window.fetch = async (...args): Promise<Response> => {
        const [request, extraArgs] = args

        const requestObject: RequestInterface = {
            url: '',
            method: 'GET',
            startTime: (new Date()).toISOString(),
            headers: {}
        }

        try {
            if (typeof request === 'string') {
                requestObject.url = request.toString()
            } else if (request instanceof Request) {
                const clonedRequest = request.clone()
                requestObject.url = clonedRequest.url ? clonedRequest.url.toString() : ''
                requestObject.method = (clonedRequest.method ? clonedRequest.method : "GET").toUpperCase()

                // adding fetch request headers
                requestObject.headers = { ...getFetchHeaders(clonedRequest.headers) }

                // adding fetch request body
                if (!clonedRequest.bodyUsed) {
                    requestObject.body = await clonedRequest.text()
                        .then((data: string) => { return data })
                        .catch(() => { return 'Unknown Format.' })
                }
            }

            // adding fetch request request headers
            if (extraArgs) {
                if (extraArgs.method) {
                    requestObject.method = extraArgs.method.toUpperCase()
                }

                // adding fetch request headers
                if (extraArgs.headers) {
                    requestObject.headers = { ...requestObject.headers, ...getFetchHeaders(extraArgs.headers) }
                }

                // adding fetch request body
                if (extraArgs.body) {
                    if (typeof extraArgs.body === 'string') {
                        try {
                            requestObject.body = extraArgs.body;
                        } catch (err) {
                            requestObject.transfer_encoding = 'base64'
                            requestObject.body = btoa(extraArgs.body);
                        }
                    } else if (typeof extraArgs.body === 'object' || typeof extraArgs.body === 'number' || typeof extraArgs.body === 'boolean') {
                        requestObject.body = extraArgs.body;
                    }
                }
            }
            // eslint-disable-next-line no-empty
        } catch (err) { }

        const response = await origFetch(...args);
        try {
            const endTime = (new Date()).toISOString()
            const clonedResponse = response.clone()

            const responseObject: ResponseInterface = {
                status: clonedResponse.status,
                endTime: endTime,
                headers: JSON.stringify(getFetchHeaders(clonedResponse.headers)),
                extra: {
                    type: clonedResponse.type,
                    redirected: clonedResponse.redirected,
                    statusText: clonedResponse.statusText,
                    url: clonedResponse.url
                }
            };

            // adding fetch response body
            const resBody = clonedResponse.text()
                .then((data) => { return data })
                .catch(() => { return false })

            Promise.all([resBody]).then((data) => {
                if (data.length) {
                    const resheaders = getFetchHeaders(clonedResponse.headers)
                    let hasAcceptedContentType = true
                    if ('content-type' in resheaders) {
                        const contentType = resheaders['content-type']
                        const acceptedTypes = ['application/json', 'application/ld+json', 'application/xml',
                            'application/xhtml+xml', 'application/javascript', 'multipart/form-data',
                            'application/x-www-form-urlencoded', 'text/css', 'text/csv', 'text/html',
                            'text/javascript', 'text/plain', 'text/xml'
                        ]
                        if (typeof contentType === 'string') {
                            hasAcceptedContentType = acceptedTypes.some(acceptedType => contentType.includes(acceptedType));
                        }
                    }
                    if (data[0] !== false && hasAcceptedContentType) {
                        responseObject.body = data[0]
                    } else {
                        responseObject.body = 'Unknown Format.'
                    }
                    recorder({
                        request: requestObject,
                        response: responseObject,
                        type: 'fetch'
                    })
                }
            })

            // eslint-disable-next-line no-empty
        } catch (err) { }
        return response;
    };
}

const addResourceEntries = (entries: PerformanceEntryList): void => {
    entries = entries.filter((entry: any) => !(entry?.initiatorType === 'fetch' || entry?.initiatorType === 'xmlhttprequest'))
    entries.map((entry: any) => {
        if (entry?.initiatorType && entry?.responseEnd) {
            //event.startTime gives DOMHighResTimeStamp (type double) and is used to store a time value in milliseconds.
            const startTime = new Date(resourceTimeStart + entry.startTime)
            //event.responseEnd gives DOMHighResTimeStamp (type double) and is used to store a time value in milliseconds.
            const endTime = new Date(resourceTimeStart + entry?.responseEnd)
            networkRecorder({
                type: entry.initiatorType,
                request: {
                    url: entry.name,
                    method: 'GET',
                    startTime: startTime.toISOString(),
                    headers: {}
                },
                response: {
                    status: 200,
                    endTime: endTime.toISOString(),
                    headers: '',
                }
            })
        }
    })
}

const captureResourceRequestEvent = (): void => {
    if ("performance" in window) {
        // The timeOrigin property of the Performance interface returns the high resolution timestamp of the start time of the performance measurement.
        resourceTimeStart = window.performance.timeOrigin
        const initialEntries = window.performance.getEntriesByType('resource')
        if (initialEntries) {
            addResourceEntries(initialEntries)
        }

        // Creating performance observer for new entries
        const perfObserver = new PerformanceObserver((observedEntries) => {
            const entries = observedEntries.getEntries();
            addResourceEntries(entries)
        });
        perfObserver.observe({
            type: 'resource',
            buffered: true
        });
    }
}

const networkRecorder = (networkEvent: NetworkEventInterface): void => {
    if (isRecording) {
        networkEvent["epochTime"] = Math.floor(Date.now() / 1000);

        //remove below to enable body capture
        if (networkEvent.request && networkEvent.request.body) {
            networkEvent.request.body = {}
        }
        if (networkEvent.response && networkEvent.response.body) {
            networkEvent.response.body = {}
        }
        //remove above to enable body capture
        if (networkEvent.request && networkEvent.request.body && isGreaterThanNetworkLimit(networkEvent.request.body)) {
            networkEvent.request['sizeInKB'] = getSizeOfData(networkEvent.request.body)
            networkEvent.request.body = {}
            networkEvent.request['limitExceeded'] = "Request size is too large"
        }
        if (networkEvent.response && networkEvent.response.body && isGreaterThanNetworkLimit(networkEvent.response.body)) {
            networkEvent.response['sizeInKB'] = getSizeOfData(networkEvent.response.body)
            networkEvent.response.body = {}
            networkEvent.response['limitExceeded'] = "Response size is too large."
        }

        // Filter network logs which contains the webapi.userexperior.online domain.
        if(!networkEvent.request?.url?.includes('https://webapi.userexperior.online')) {
            networkLogs.push(networkEvent)
        }
    }
}

const isGreaterThanNetworkLimit = (payload: unknown): boolean => {
    const kB: number = getSizeOfData(payload)
    if (kB <= DEFAULT_NETWORK_BODY_SIZE) {
        return false
    }
    return true
}

export let networkLogs: Array<object> = []

export const resetNetworkData = (): void => {
    networkLogs = []
}


export const initalizeNetworkLog = (): void => {
    captureXMLHttpRequestEvent(networkRecorder)
    captureFetchRequestEvent(networkRecorder)
    captureResourceRequestEvent()
}

