import { isRecording } from "../app";


export let consoleLogs: Array<object> = []

export const resetConsoleData = (): void => {
    consoleLogs = []
}

const getShortUrlFromFullUrl = (fullUrl: string): string => {
    let shortUrl = fullUrl
        .substr(fullUrl.lastIndexOf("/") + 1)
        .split(":")
        .splice(0, 2)
        .join(":");
    if (shortUrl.lastIndexOf(":") == 0) {
        shortUrl = "(index)" + shortUrl;
    }
    return shortUrl;
}

const getConsoleLocationFromStack = (): object => {
    try {
        throw new Error();
    } catch (ea: any) {
        const fullUrl: string = ea.stack.substr(ea.stack.lastIndexOf("at ") + 3);
        const shortUrl = getShortUrlFromFullUrl(fullUrl);
        return { full_url: fullUrl, short_url: shortUrl };
    }
}

const getErrorMessage = (error: Error): string => {
    return `${error?.message} \n ${error?.stack}`
}

const typeString = (arg: any): string => {
    if (arg === undefined) {
        return 'undefined';
    }
    if (arg === null) {
        return 'null';
    }
    if (arg instanceof Error) {
        return getErrorMessage(arg);
    }
    if (Array.isArray(arg)) {
        return `Array(${arg.length})`;
    }
    return String(arg);
}

const typeFloat = (arg: any): string => {
    if (typeof arg !== 'number') return 'NaN';
    return arg.toString();
}

const typeInt = (arg: any): string => {
    if (typeof arg !== 'number') return 'NaN';
    return Math.floor(arg).toString();
}

const typeObject = (arg: any): string => {
    if (arg === undefined) {
        return 'undefined';
    }
    if (arg === null) {
        return 'null';
    }
    if (arg instanceof Error) {
        return getErrorMessage(arg);
    }
    if (Array.isArray(arg)) {
        const length = arg.length;
        const values = arg.slice(0, 10).map(typeString).join(', ');
        return `Array(${length})[${values}]`;
    }
    if (typeof arg === 'object') {
        const res = [];
        let i = 0;
        for (const k in arg) {
            if (++i === 10) {
                break;
            }
            const v = arg[k];
            res.push(k + ': ' + typeString(v));
        }
        return '{' + res.join(', ') + '}';
    }
    return arg.toString();
}

const getConsoleValue = (args: any[]): string => {
    if (typeof args[0] === 'string') {
        args.unshift(
            args.shift().replace(/%(o|s|f|d|i)/g, (s: string, t: string): string => {
                const arg = args.shift();
                alert(`${args} ${arg} ${s} ${t}`)
                if (arg === undefined) return s;
                switch (t) {
                    case 'o':
                        return typeObject(arg);
                    case 's':
                        return typeString(arg);
                    case 'f':
                        return typeFloat(arg);
                    case 'd':
                    case 'i':
                        return typeInt(arg);
                    default:
                        return s;
                }
            }),
        );
    }
    return args.map(typeObject).join(' ');
}

const addLog = (type: string, args: unknown[]): void => {
    if (isRecording) {
        const urls: object = getConsoleLocationFromStack();
        consoleLogs.push({
            type: type,
            datetime: Date().toLocaleString(),
            value: getConsoleValue(args),
            epochTime: Math.floor(Date.now() / 1000),
            ...urls,
        });
    }
};

const captureWindowErrors = (msg: string | Event, url: string | undefined, lineNo: number | undefined, columnNo: number | undefined, error: Error | undefined): boolean => {
    const fullUrl: string = url + ":" + lineNo + ":" + columnNo;
    const shortUrl: string = getShortUrlFromFullUrl(fullUrl);
    if (isRecording) {
        consoleLogs.push({
            type: "exception",
            datetime: Date().toLocaleString(),
            value: msg,
            extra: {
                msg: msg,
                url: url,
                lineNo: lineNo,
                columnNo: columnNo,
                error: error,
            },
            epochTime: Math.floor(Date.now() / 1000),
            full_url: fullUrl,
            short_url: shortUrl,
        });
    }
    return false;
};

const trackConsole = (console: Console): void => {
    const consoleTypes: string[] = ['log', 'info', 'warn', 'error', 'debug', 'assert'];
    consoleTypes.forEach((type) => {
        const bindConsole = (console as any)[type];
        (console as any)[type] = function (...args: unknown[]): void {
            bindConsole.apply(this, args);
            addLog(type, args);
        };
    });

    //to handle window errors like syntax error etc..
    window.onerror = captureWindowErrors
}


export const initalizeConsoleLog = (): void => {
    trackConsole(window.console);
}

//console log types and change the getConsoleValue function
