export const IN_BROWSER = !(typeof window === "undefined");

const APP_ENV = 'Production'
export const SDK_VERSION = "0.2.42"

let host = 'http://localhost:7899';

if (APP_ENV === 'Production') {
    host = 'https://webmonitoringapi.userexperior.online';
}

export const DEFAULT_BASE_URL = host + '/api/ingest'

//individual url paths
export const INITILIZE_SESSION_PATH = '/startSession/'
export const INITILIZE_USER_PATH = '/setIdentifier/'
export const SEND_LOGS_PATH = '/logData/'

// to restrict, sending request and response body if it reaches certain limit
export const DEFAULT_NETWORK_BODY_SIZE = 256 //in kB or Kilo Bytes

// send data after reaching certain threshold
export const DEFAULT_POLLING_INTERVAL = 15 //in seconds
export const MAX_SIZE_THRESHOLD = 512 //in kB or Kilo Bytes

// session throttling
export const DEFAULT_INACTIVITY_THROTTLE = 30 * 60 // in seconds
export const DEFAULT_INACTIVITY_COOLING_PERIOD = 300 //in millseconds

export const DEFAULT_VISIBILITY_THROTTLE = 60 // in seconds
export const DEFAULT_SESSION_THROTTLE = 20 // in seconds
export const DEFAULT_RAGE_THROTTLE = 200 // in ms


//default session replay options
export const DEFAULT_SESSION_REPLAY_BLOCK_CLASS = 'ue-block'
export const DEFAULT_SESSION_REPLAY_MASK_CLASS = 'ue-mask'
export const CAPTURE_IMAGES_INLINE = false
export const CAPTURE_INLINE_STYLESHEET = true
export const MASK_ALL_INPUTS = false
export const MASK_INPUT_CLASS = 'ue-input-mask'
export const DEFAULT_MASK_INPUT_OPTIONS = {
    password: true,
    email: true,
    tel: true
}