import { MAX_SIZE_THRESHOLD, DEFAULT_POLLING_INTERVAL, DEFAULT_BASE_URL, INITILIZE_SESSION_PATH, INITILIZE_USER_PATH, SEND_LOGS_PATH, SDK_VERSION } from '../utils';
import { getSizeOfData } from '../lib/helper'
import { consoleLogs, resetConsoleData } from '../lib/collectLog';
import { networkLogs, resetNetworkData } from '../lib/collectNetwork';
import { eventLogs, resetEventData } from '../lib/collectEvents';
import { sessionEvents, resetSessionEvents, lastUploadedTime, setLastUploadedTime, logEvents, resetLogEvents } from './index'

interface ApiInterface {
    createSession(data: object): void;
    sendUser(data: object): void;
    sendLogs(force: boolean, stopRecording: () => void): void;
    sendRequest(urlPath: string, data: object, method: string): void;
}

interface EventsPayload {
    sdkVersion: string;
    domNavigationTimings?: object;
    sessionReplay: object[];
    consoleLogs: object[];
    networkLogs: object[];
    eventLogs: object[];
    events: object[];
}

export default class Api implements ApiInterface {
    private readonly projectKey: string;
    private readonly sessionId: string;
    private readonly tabId: string;
    private domNavigationTimingsSent: boolean;
    constructor(projectKey: string, sessionId: string, tabId: string) {
        this.projectKey = projectKey
        this.sessionId = sessionId
        this.tabId = tabId
        this.domNavigationTimingsSent = false;
    }
    createSession(data: object): void {
        // send prodcutkey, system info, sessionid, tabid
        this.sendRequest(INITILIZE_SESSION_PATH, data)
    }
    sendUser(data: object): void {
        //send user pk and session id to connect with user with session and user traits
        this.sendRequest(INITILIZE_USER_PATH, data)
    }
    sendLogs(force = false, stopRecording: () => void): void {
        let eventsData: EventsPayload = {
            sdkVersion: SDK_VERSION,
            sessionReplay: sessionEvents,
            consoleLogs: consoleLogs,
            networkLogs: networkLogs,
            eventLogs: eventLogs,
            events: logEvents,
        };

        if(document.readyState === "complete" && !this.domNavigationTimingsSent) {
            const entries = performance.getEntriesByType("navigation");
            entries.forEach((entry) => {
                eventsData = {
                    ...eventsData,
                    domNavigationTimings: entry.toJSON(),
                }
            });
        }

        const eventsDataSize = getSizeOfData(eventsData)
        const timeElapsed = Math.floor(Date.now() / 1000) - lastUploadedTime

        const hasAnyData = eventsData.sessionReplay.length > 0 ||
            eventsData.consoleLogs.length > 0 ||
            eventsData.networkLogs.length > 0 ||
            eventsData.eventLogs.length > 0 ||
            eventsData.events.length > 0

        const hasTimeElapsed = timeElapsed >= DEFAULT_POLLING_INTERVAL

        // check if the time elapsed is greater than the polling interval and 
        // if the size of the data is greater than threshold
        if ((hasTimeElapsed && hasAnyData) || eventsDataSize >= MAX_SIZE_THRESHOLD || force) {

            if(eventsData.hasOwnProperty('domNavigationTimings')) {
                this.domNavigationTimingsSent = true
            }

            this.sendRequest(SEND_LOGS_PATH, eventsData).then(responseCode => {
                if (responseCode === 401) {
                    // Stop recording.
                    stopRecording()
                    console.warn("UserExperior: the recording has been stopped, the version key is invalid or your session limit has reached.")
                }
            }).catch(error => {
                console.log(error)
            })
            setLastUploadedTime()
            resetSessionEvents()
            resetConsoleData()
            resetNetworkData()
            resetEventData()
            resetLogEvents()
        }
    }
    async sendRequest(urlPath: string, data: object, method = "POST"): Promise<number> {
        // send request to server with the preflight timestamp
        const requestBody = {
            versionKey: this.projectKey,
            sessionId: this.sessionId,
            tabId: this.tabId,
            timestamp: Math.floor(Date.now() / 1000),
            ...data,
        }

        const headers = {
            'Content-Type': 'application/json',
            'ue-vk': this.projectKey
        }

        try {
            const response = await fetch(DEFAULT_BASE_URL + urlPath, {
                method: method,
                headers: headers,
                body: JSON.stringify(requestBody)
            })
            return response.status
        } catch (error) {
            console.log(error)
            return 0
        }
    }
}