import { DEFAULT_INACTIVITY_COOLING_PERIOD, DEFAULT_INACTIVITY_THROTTLE, DEFAULT_VISIBILITY_THROTTLE } from "../utils";

export class UserIdleTracker {
    private readonly activityTimeout: number;
    private readonly visiblityTimeout: number;
    private interval: NodeJS.Timer | undefined;
    private timeOutTracker: NodeJS.Timer | undefined;
    private handleActivityEvent: () => void;
    private handlePageVisiblityEvent: () => void;
    private pageVisibleTimeout: NodeJS.Timeout | undefined;
    private activeInterval: NodeJS.Timer | undefined;
    private restartSession: () => void;

    constructor(restartSession: () => void) {
        this.activityTimeout = DEFAULT_INACTIVITY_THROTTLE
        this.visiblityTimeout = DEFAULT_VISIBILITY_THROTTLE
        this.interval = undefined
        this.activeInterval = undefined
        this.restartSession = restartSession
        this.handleActivityEvent = this.updateSessionExpireTime.bind(this);
        this.handlePageVisiblityEvent = this.pageVisibilityEventHandler.bind(this);
        this.initalizeTracker();
        this.startActivityTracker();
    }
    updateSessionExpireTime() {
        //updating the user activity
        if (this.timeOutTracker) {
            clearTimeout(this.timeOutTracker)
        }
        this.timeOutTracker = setTimeout(() => {
            localStorage.setItem("ue_session_expire", (Date.now() + (this.activityTimeout * 1000)).toString())
        }, DEFAULT_INACTIVITY_COOLING_PERIOD)
    }
    startActivityTracker() {
        this.updateSessionExpireTime();

        //interval to track user activity in any tabs
        this.interval = setInterval(() => {
            // // restart session when user is idle for 30 mins.
            const activityExpireTime = parseInt(localStorage.getItem('ue_session_expire') || '0', 10);
            if (activityExpireTime < Date.now()) {
                this.restartSession()
            }
        }, 1000)

        //interval to track active tabs
        this.activeInterval = setInterval(() => {
            if (!document.hidden) {
                localStorage.setItem("ue_session_active", Date.now().toString())
            }
        }, 3000)
    }
    pageVisibilityEventHandler() {
        // restart session when user went to another tab or window or minimized.
        if (document.hidden) {
            this.pageVisibleTimeout = setTimeout(() => {
                const lastActiveTime = parseInt(localStorage.getItem('ue_session_active') || '0', 10);
                if (Math.floor((Date.now() - lastActiveTime) / 1000) >= this.visiblityTimeout) {
                   localStorage.setItem("restart_session", "true");
                }
            }, this.visiblityTimeout * 1000)
        } else {
            const lastActiveTime = parseInt(localStorage.getItem('ue_session_active') || '0', 10);
            const restartSession = localStorage.getItem("restart_session");
            if (Math.floor((Date.now() - lastActiveTime) / 1000) < this.visiblityTimeout) {
                clearTimeout(this.pageVisibleTimeout)
            }
            // Restart session when the user comes back on the session performing tab.
            if(restartSession && restartSession === "true") {
                localStorage.removeItem("restart_session");
                this.restartSession();
            }
        }
    }
    initalizeTracker() {
        // event listeners handle session behaviour
        window.addEventListener("click", this.handleActivityEvent)
        window.addEventListener("scroll", this.handleActivityEvent)
        window.addEventListener("keydown", this.handleActivityEvent)
        window.addEventListener("visibilitychange", this.handlePageVisiblityEvent)
    }
    cleanUpTracker() {
        // clearing event listeners, intervals and timeouts
        clearInterval(this.interval)
        clearInterval(this.activeInterval)
        clearTimeout(this.pageVisibleTimeout)
        window.removeEventListener("click", this.handleActivityEvent)
        window.removeEventListener("scroll", this.handleActivityEvent)
        window.removeEventListener("keydown", this.handleActivityEvent)
        window.removeEventListener("visibilitychange", this.handlePageVisiblityEvent)
    }
}